import {FailedToFetchError} from "../../../error_handling/error_types.js"

export const canUpdatePaymentIntent = () => ({
  updatePaymentIntent: async ({ intentParams,
    paymentIntentIdStripe, paymentMethodIdStripe, stripePaymentIntentId }) => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    const stripePaymentIntentParams = intentParams
      stripePaymentIntentParams.paymentIntentIdStripe = paymentIntentIdStripe
      stripePaymentIntentParams.paymentMethodIdStripe = paymentMethodIdStripe
      stripePaymentIntentParams.stripePaymentIntentId = stripePaymentIntentId

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response =
      await fetch(`/stripe_payment_intents/${stripePaymentIntentId}`, {
        headers: headerContent,
        method: "PATCH",
        body: JSON.stringify(stripePaymentIntentParams)
      })

    if (!response.ok) {
      // Network error
      throw new FailedToFetchError(
        `Failed to fetch stripe_payment_intents_controller#update`
      )
    }

    const paymentIntentResponse = await response.json()

    if (paymentIntentResponse.error) {
      const paymentIntentStripe = paymentIntentResponse.error.payment_intent

      if (typeof(paymentIntentStripe) !== "undefined") {
        sessionStorage.setItem(
          "latestPaymentIntentStatus",
          paymentIntentStripe.status
        )
      } else {
        sessionStorage.clear()
      }

      throw paymentIntentResponse
    }

    if (!paymentIntentResponse.payment_intent_stripe) {
      throw new FailedToFetchError(
        "Failed to fetch/update `/stripe_payment_intents`: "+
        "`payment_intent_stripe` not set"
      )
    }

    return paymentIntentResponse.payment_intent_stripe
  }
})