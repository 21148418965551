import {FailedToFetchError} from "../../../../error_handling/error_types.js"

export const canCreateCustomProductAndPrice = ({ donation, user, payment_type, }) => ({
  createCustomProductAndPrice: async () => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    const stripe_products_params = {
      payment_type: payment_type,
      campaign_id: donation.campaignId,
      email: user.email,
      password: user.password,
      firstname: user.firstName,
      lastname: user.lastName,
      country: user.country,
      zip: user.zip,
      currency_code: donation.currencyCode,
      amount: donation.amount,
      frequency: donation.frequency,
      tip_percent: donation.tipPercent,
    }

    if (typeof(donation.tipPercent) !== "undefined") {
      stripe_products_params.tip_percent = donation.tipPercent
    }

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response = await fetch("/stripe_products", {
      headers: headerContent,
      method: "POST",
      body: JSON.stringify(stripe_products_params)
    })

    if (!response.ok) {
      // Network error
      throw new FailedToFetchError("Failed to fetch '/stripe_products'")
    }

    const priceId = await response.json()

    if (priceId.error) {
      throw priceId
    }

    if (!priceId.price_id_stripe) {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_products`: `price_id_stripe` not set"
      )
    }

    return priceId.price_id_stripe
  }
})