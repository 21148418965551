import {StripeError} from "../../../error_handling/error_types.js"

export const canHandleStripePaymentRequiresCustomerAction = ({ stripe, }) => ({
  handleStripePaymentRequiresCustomerAction: async ({
    initialPaymentIntent, paymentMethodId, isRetry, }) => {
    if (initialPaymentIntent.status === "requires_action" ||
      (isRetry === true && initialPaymentIntent.status === "requires_payment_method")){
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        initialPaymentIntent.client_secret,
        { payment_method: paymentMethodId, }
      )

      if (error) {
        throw new StripeError({ stripe_error: error })
      }

      return paymentIntent
    }

    return initialPaymentIntent
  }
})