import {FailedToFetchError} from "../../../error_handling/error_types.js"

export const canCreatePaymentIntent = () => ({
  createPaymentIntent: async ({ intentParams, customerIdStripe,
    paymentMethodIdStripe }) => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    const stripePaymentIntentParams = intentParams
    stripePaymentIntentParams.customerIdStripe = customerIdStripe
    stripePaymentIntentParams.paymentMethodIdStripe = paymentMethodIdStripe

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response = await fetch("/stripe_payment_intents", {
      headers: headerContent,
      method: "POST",
      body: JSON.stringify(stripePaymentIntentParams)
    })

    // TODO: need to migrate error to webpacker and catch thrown error
    if (!response.ok) {
      // Network error
      throw new FailedToFetchError("Failed to fetch '/stripe_payment_intents'")
    }

    const paymentIntentResponse = await response.json()

    if (paymentIntentResponse.error) {
      const paymentIntentStripe = paymentIntentResponse.error.payment_intent

      if (typeof(paymentIntentStripe) !== "undefined") {
        sessionStorage.setItem(
          "campaignId",
          stripePaymentIntentParams.campaign_id
        )
        sessionStorage.setItem("customerIdStripe", customerIdStripe)
        // TODO: Rename to paymentIntentIdStripe
        sessionStorage.setItem("paymentIntentId", paymentIntentStripe.id)
        sessionStorage.setItem(
          "stripePaymentIntentId",
          paymentIntentResponse.stripe_payment_intent_id
        )
        sessionStorage.setItem(
          "latestPaymentIntentStatus",
          paymentIntentStripe.status
        )
      }

      throw paymentIntentResponse
    }

    if (!paymentIntentResponse.payment_intent_stripe) {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_payment_intents`: `payment_intent_stripe` " +
        "not set"
      )
    }

    return paymentIntentResponse.payment_intent_stripe
  }
})