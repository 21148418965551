export default function log_error({ type, response, message, display_message,
  campaign_id, cause_id, }) {

  let csrfToken = null

  if (document.querySelector("[name='csrf-token']") != null) {
    // Grab the CSRF token from the meta tag
    csrfToken = document.querySelector("[name='csrf-token']").content
  }

  let post_params = { type: type }

  if (type == "stripe") {
    post_params["response"] = response
  } else {
    post_params["error"] = message
  }

  if (typeof(display_message) !== "undefined") {
    post_params["display_message"] = display_message
  }

  if (typeof(campaign_id) !== "undefined") {
    post_params["campaign_id"] = campaign_id
  }

  if (typeof(cause_id) !== "undefined") {
    post_params["cause_id"] = cause_id
  }

  let headerContent = {
    "Content-Type": "application/json"
  }

  if (csrfToken != null) {
    headerContent["X-CSRF-Token"] = csrfToken
  }

  fetch("/log_error", {
    headers: headerContent,
    method: "POST",
    body: JSON.stringify(post_params)
  }).then(response => {
    if(response.ok) {
      return response.json();
    } else {
      throw Error(`Request rejected with status ${response.status}`);
    }
  })
}