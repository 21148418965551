import {StripeRequiresPaymentMethod} from "../../../error_handling/error_types.js"

export const canHandleStripeRequiresPaymentMethod = () => ({
  handleStripeRequiresPaymentMethod: ({ campaignId, causeId, customerIdStripe,
    paymentIntent, subscription, }) => {

    // Store the latest invoice ID and status.
    if (typeof(campaignId) != "undefined") {
      sessionStorage.setItem("campaignId", campaignId)
    }

    if (typeof(causeId) != "undefined") {
      sessionStorage.setItem("causeId", causeId)
    }

    sessionStorage.setItem("customerIdStripe", customerIdStripe)

    if (typeof(subscription) != "undefined") {
      sessionStorage.setItem("latestInvoiceId", subscription.latest_invoice.id)
      sessionStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      )
    } else if (typeof(paymentIntent) != "undefined") {
      sessionStorage.setItem("paymentIntentId", paymentIntent.id)
      sessionStorage.setItem(
        "latestPaymentIntentStatus",
        paymentIntent.status
      )
    }

    throw new StripeRequiresPaymentMethod(
      "Card declined - Stripe Payment Intent status: `requires_payment_method`",
      { display_message: "Your card was declined." }
    )
  }
})