export const stripeRecurringDonationProcessor = ({ stripeDonation, }) => {
  const processPayment = async () => {
    try {
      stripeDonation.hideNonFieldSpecificError()

      let human = await stripeDonation.validateHuman()

      if (human) {
        const priceIdStripe = await stripeDonation.createCustomProductAndPrice()

        const sessionCampaignId = sessionStorage.getItem("campaignId")
        let customerIdStripe = sessionStorage.getItem("customerIdStripe")

        if (customerIdStripe && sessionCampaignId != stripeDonation.campaignId) {
          sessionStorage.clear()

          customerIdStripe = null
        }

        if (!customerIdStripe) {
          customerIdStripe = await stripeDonation.createStripeCustomer()
        }

        const paymentMethod = await stripeDonation.createStripePaymentMethod()

        const latestInvoicePaymentIntentStatus = sessionStorage.getItem(
          "latestInvoicePaymentIntentStatus"
        )

        let paymentIntent = null

        if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
          const invoiceId = sessionStorage.getItem("latestInvoiceId")

          const invoice = await stripeDonation.retryInvoiceWithNewPaymentMethod({
            invoiceId: invoiceId,
            customerId: customerIdStripe,
            paymentMethodId: paymentMethod.id,
          })

          paymentIntent =
            await stripeDonation.handleStripePaymentRequiresCustomerAction({
              initialPaymentIntent: invoice.payment_intent,
              paymentMethodId: paymentMethod.id,
              isRetry: true,
            })
        } else {
          const subscription = await stripeDonation.createStripeSubscription({
            customerId: customerIdStripe,
            paymentMethodId: paymentMethod.id,
            priceId: priceIdStripe,
          })

          if (subscription.status === "active") {
            // Subscription is active, no customer actions required.
            paymentIntent = subscription.latest_invoice.payment_intent
          } else {
            paymentIntent =
              await stripeDonation.handleStripePaymentRequiresCustomerAction({
                initialPaymentIntent: subscription.latest_invoice.payment_intent,
                paymentMethodId: paymentMethod.id,
                isRetry: false,
              })
          }

          if (paymentIntent.status === "requires_payment_method") {
            stripeDonation.handleStripeRequiresPaymentMethod({
              campaignId: stripeDonation.campaignId,
              customerIdStripe: customerIdStripe,
              subscription: subscription,
            })
          }
        }

        if (paymentIntent.status == "succeeded") {
          stripeDonation.completePayment(paymentIntent.id)
        } else {
          // TODO: How handle status != "succeeded"??
        }
      }
    } catch(e) {
      stripeDonation.handleStripeProcessingError({
        e: e,
        campaignId: stripeDonation.campaignId,
        paymentType: "donation",
      })
    }
  }

  return {
    processPayment,
  }
}