/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// TODO: Once complete migration of all javascript from websockets, remove
// the gems: sass-rails, uglifier, jquery-rails, turbolinks, coffee-rails gems
// and start requiring the packages here instead (there might be other gems that
// can be removed as well).
// See note in: https://gist.github.com/maxivak/2612fa987b9f9ed7cb53a88fcba247b3

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import {donation} from "./src/payments/donation.js"
import {donationForm} from "./src/donations/donation_form.js"
import {causeSubscription} from "./src/payments/cause_subscription.js"

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById("donate-now-form")) {
    donation()
    donationForm()
  } else if (document.getElementById("new_complete_your_membership_form")) {
    causeSubscription()
  }
})