import {FailedToFetchError} from "../../../error_handling/error_types.js"

export const canCreateStripeCustomer = ({ user, donation, causeSubscription, paymentType = "donation" }) => ({
  createStripeCustomer: async () => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    const stripeCustomerParams = { payment_type: paymentType }

    // user is only passed in for donation by user that is not logged in
    if (typeof(user) != "undefined") {
      stripeCustomerParams.email = user.email
      stripeCustomerParams.password = user.password
      stripeCustomerParams.firstname = user.firstName
      stripeCustomerParams.lastname = user.lastName
      stripeCustomerParams.country = user.country
      stripeCustomerParams.zip = user.zip
    }

    if (typeof(donation) != "undefined") {
      stripeCustomerParams.campaign_id = donation.campaignId
    } else if (typeof(causeSubscription) !== "undefined") {
      stripeCustomerParams.cause_id = causeSubscription.causeId
    } else {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_users`: Neither `donation` nor " +
        "`causeSubscription` was set"
      )
    }

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response = await fetch("/stripe_users", {
      headers: headerContent,
      method: "POST",
      body: JSON.stringify(stripeCustomerParams)
    })

    if (!response.ok) {
      // Network error
      throw new FailedToFetchError("Failed to fetch '/stripe_users'")
    }

    const customerId = await response.json()

    if (customerId.error) {
      throw customerId
    }

    if (!customerId.customer_id_stripe) {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_users`: `customer_id_stripe` not set"
      )
    }

    return customerId.customer_id_stripe
  }
})