// This section contains CC processing code for Stripe
export const loadUpdateConfirmationAmounts = () => {
  // Update Confirmation amount, tip and total when currency changes
  $('#donate_now_form_currency_code').on('change', function(e) {
    updateConfirmationAmounts();
  });

  // Update Confirmation amount, tip and total when amount is changes
  $('#donate_now_form_amount').on('change', function(e) {
    updateConfirmationAmounts();
  });

  // Update Confirmation amount, tip and total when tip is changes
  $('#donate_now_form_tip_percent').on('change', function(e) {
    updateConfirmationAmounts();
  });

  let total = undefined
  // var form = document.getElementById('new_donate_now_form'),
  //       stripe, country, options, total;

  function current_amount() {
    return parseFloat($('#donate_now_form_amount').val());
  }

  function current_tip_percent() {
    return parseFloat($('#donate_now_form_tip_percent').val()) / 100.0;
  }

  function formatCurrency(amount) {
    // https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places/22977058
    var amountRounded = Number(Math.round(parseFloat(amount) + 'e2') + 'e-2').
      toFixed(2);

    return amountRounded.replace(/(\d)(?=(\d{3})+\.)/g, "$1,").
      toString();
  }

  function amountPlusTip() {
    var amount = parseFloat($('#donate_now_form_amount').val()),
      tip_percent = parseFloat($('#donate_now_form_tip_percent').val()) / 100.0;

    return amount += (amount * tip_percent);
  }

  function tipAmount() {
    var amount = parseFloat($('#donate_now_form_amount').val()),
      tip_percent = parseFloat($('#donate_now_form_tip_percent').val()) / 100.0;

    return amount * tip_percent;
  }

  function updateConfirmationAmounts() {
    var amount = current_amount(),
      tip_percent = current_tip_percent();

    if ($.isNumeric(amount) && amount >= 0) {
      $('.donation-confirmation-amount').
        html($("#donate_now_form_currency_code option:selected").text() +
          formatCurrency(amount));

      if ($.isNumeric(tip_percent) && tip_percent >= 0) {

        // Set the tip amount in the donation confirmation section
        $('.donation-confirmation-tip-amount').
          html($("#donate_now_form_currency_code option:selected").text() +
            formatCurrency(tipAmount()));

        // Set the total amount in the donation confirmation section
        // i.e., amount + tip
        total = amountPlusTip();
      } else {
        total = amount;
      }

      $(".donation-confirmation-total-amount").
        html($("#donate_now_form_currency_code option:selected").text() +
          formatCurrency(total));
    }
  }
};