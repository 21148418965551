import {notBlank} from "../../helpers/string_helpers.js"
import {setBillingName} from "./capabilities/can_set_billing_name.js"
import {canSetPaymentIntentParamsFromDonationForm} from "./capabilities/donations/can_set_payment_intent_params_from_donation_form.js"
import {canCreateStripeCustomer} from "./capabilities/can_create_stripe_customer.js"
import {canValidateHuman} from "./capabilities/can_validate_human.js"
import {canCreateStripePaymentMethod} from "./capabilities/can_create_stripe_payment_method.js"
import {canCreatePaymentIntent} from "./capabilities/can_create_payment_intent.js"
import {canUpdatePaymentIntent} from "./capabilities/can_update_payment_intent.js"
import {canHandleStripePaymentRequiresCustomerAction} from "./capabilities/can_handle_stripe_payment_requires_customer_action.js"
import {canHandleStripeRequiresPaymentMethod} from "./capabilities/can_handle_stripe_requires_payment_method.js"
import {canCompletePayment} from "./capabilities/can_complete_payment.js"
import {canHideNonFieldSpecificError} from "./capabilities/can_hide_non_field_specific_error.js"
import {canHandleStripeProcessingErrors} from "./capabilities/can_handle_stripe_processing_errors.js"

export const stripeOneTimeDonationCreator = ({ stripe, card, form, form_button,
                                               user, donation, }) => {
  return Object.assign(
    donation,
    user,
    canSetPaymentIntentParamsFromDonationForm({ donation: donation, user: user, }),
    canCreateStripeCustomer({ donation: donation, user: user, }),
    canValidateHuman({ donation: donation, user: user, }),
    canCreateStripePaymentMethod({
      billingName: setBillingName({
        user: user,
        current_user_name: gon.current_user_name
      }),
      stripe: stripe,
      card: card,
      email: (notBlank(user.email)) ? user.email : gon.current_user_email,
      country: user.country,
      zip: user.zip,
    }),
    canCreatePaymentIntent(),
    canHandleStripePaymentRequiresCustomerAction({ stripe: stripe, }),
    canHandleStripeRequiresPaymentMethod(),
    canUpdatePaymentIntent(),
    canCompletePayment({ form: form, }),
    canHideNonFieldSpecificError(),
    canHandleStripeProcessingErrors({ form_button: form_button }),
  )
}