export const preventDoubleSubmit = (form_button) => {
  form_button.classList.add("disabled")

  form_button.setAttribute("aria-disabled", "true")
}

export const enableSubmit = (form_button) => {
  form_button.classList.remove("disabled")

  form_button.removeAttribute("aria-disabled")
}