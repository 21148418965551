import {StripeError} from "../../../error_handling/error_types.js"
import countryThreeLetterCodeToTwo from "../../../helpers/country_code.js"

export const canCreateStripePaymentMethod = ({ billingName, stripe, card,
                                               email, country, zip}) => ({
  createStripePaymentMethod: async () => {
    let paymentMethodParams = {
      type: "card",
      card: card,
      billing_details: {
        name: billingName,
        email: email,
        address: {
          postal_code: zip,
          country: countryThreeLetterCodeToTwo(country),
        }
      },
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod(
      paymentMethodParams
    )

    if (error) {
      throw new StripeError({ stripe_error: error })
    }

    return paymentMethod
  }
})