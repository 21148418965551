export default function countryThreeLetterCodeToTwo(threeLetterCode) {
  const codes = {
    USA: 'us',
    AUS: 'au',
    GBR: 'gb',
    NZL: 'nz',
    CAN: 'ca',
    FRA: 'fr',
    ZAF: 'za',
    AFG: 'af',
    ALB: 'al',
    DZA: 'dz',
    ASM: 'as',
    AND: 'ad',
    AGO: 'ao',
    AIA: 'ai',
    ATA: 'aq',
    ATG: 'ag',
    ARG: 'ar',
    ARM: 'am',
    ABW: 'aw',
    AUT: 'at',
    AZE: 'az',
    BHS: 'bs',
    BHR: 'bh',
    BGD: 'bd',
    BRB: 'bb',
    BLR: 'by',
    BEL: 'be',
    BLZ: 'bz',
    BEN: 'bj',
    BMU: 'bm',
    BTN: 'bt',
    BOL: 'bo',
    BIH: 'ba',
    BWA: 'bw',
    BVT: 'bv',
    BRA: 'br',
    IOT: 'io',
    BRN: 'bn',
    BGR: 'bg',
    BFA: 'bf',
    BDI: 'bi',
    KHM: 'kh',
    CMR: 'kh',
    CPV: 'cv',
    CYM: 'ky',
    CAF: 'cf',
    TCD: 'td',
    CHL: 'cl',
    CHN: 'cn',
    CXR: 'cx',
    CCK: 'cc',
    COL: 'co',
    COM: 'km',
    COG: 'cg',
    COK: 'ck',
    CRI: 'cr',
    CIV: 'ci',
    HRV: 'hr',
    CUB: 'cu',
    CYP: 'cy',
    CZE: 'cz',
    DNK: 'dk',
    DJI: 'dj',
    DMA: 'dm',
    DOM: 'do',
    TMP: 'tp',
    ECU: 'ec',
    EGY: 'eg',
    SLV: 'sv',
    GNQ: 'gq',
    ERI: 'er',
    EST: 'ee',
    ETH: 'et',
    FLK: 'fk',
    FRO: 'fo',
    FJI: 'fj',
    FIN: 'fi',
    FXX: 'fx',
    GUF: 'gf',
    PYF: 'pf',
    ATF: 'tf',
    GAB: 'ga',
    GMB: 'gm',
    GEO: 'ge',
    DEU: 'de',
    GHA: 'gh',
    GIB: 'gi',
    GRC: 'gr',
    GRL: 'gl',
    GRD: 'gd',
    GLP: 'gp',
    GUM: 'gu',
    GTM: 'gt',
    GIN: 'gn',
    GNB: 'gw',
    GUY: 'gy',
    HTI: 'ht',
    HMD: 'hm',
    HND: 'hn',
    HKG: 'hk',
    HUN: 'hu',
    ISL: 'is',
    IND: 'in',
    IDN: 'id',
    IRN: 'ir',
    IRQ: 'iq',
    IRL: 'ie',
    ISR: 'il',
    ITA: 'it',
    JAM: 'jm',
    JPN: 'jp',
    JOR: 'jo',
    KAZ: 'kz',
    KEN: 'ke',
    KIR: 'ki',
    PRK: 'kp',
    KOR: 'kr',
    KWT: 'kw',
    KGZ: 'kg',
    LAO: 'la',
    LVA: 'lv',
    LBN: 'lb',
    LSO: 'ls',
    LBR: 'lr',
    LBY: 'ly',
    LIE: 'li',
    LTU: 'lt',
    LUX: 'lu',
    MAC: 'mo',
    MKD: 'ks',
    MDG: 'mg',
    MWI: 'mw',
    MYS: 'my',
    MDV: 'mv',
    MLI: 'ml',
    MLT: 'mt',
    MHL: 'mh',
    MTQ: 'mq',
    MRT: 'mr',
    MUS: 'mu',
    MYT: 'yt',
    MEX: 'mx',
    FSM: 'fm',
    MDA: 'md',
    MCO: 'mc',
    MNG: 'mn',
    MSR: 'ms',
    MAR: 'ma',
    MOZ: 'mz',
    MMR: 'mm',
    NAM: 'na',
    NRU: 'nr',
    NPL: 'np',
    NLD: 'nl',
    ANT: 'an',
    NCL: 'nc',
    NIC: 'ni',
    NER: 'ne',
    NGA: 'ng',
    NIU: 'nu',
    NFK: 'nf',
    MNP: 'mp',
    NOR: 'no',
    OMN: 'om',
    PAK: 'pk',
    PLW: 'pw',
    PAN: 'pa',
    PNG: 'pg',
    PRY: 'py',
    PER: 'pe',
    PHL: 'ph',
    PCN: 'pn',
    POL: 'pl',
    PRT: 'pt',
    PRI: 'pr',
    QAT: 'qa',
    REU: 're',
    ROM: 'ro',
    RUS: 'ru',
    RWA: 'rw',
    KNA: 'kn',
    LCA: 'lc',
    VCT: 'vc',
    WSM: 'ws',
    SMR: 'sm',
    STP: 'st',
    SAU: 'sa',
    SEN: 'sn',
    SYC: 'SC',
    SLE: 'sl',
    SGP: 'sg',
    SVK: 'sk',
    SVN: 'si',
    SLB: 'sb',
    SOM: 'so',
    SGS: 'gs',
    ESP: 'es',
    LKA: 'lk',
    SHN: 'sh',
    SPM: 'pm',
    SDN: 'sd',
    SUR: 'sr',
    SJM: 'sj',
    SWZ: 'sz',
    SWE: 'se',
    CHE: 'sh',
    SYR: 'sy',
    TWN: 'tw',
    TJK: 'tj',
    TZA: 'tz',
    THA: 'th',
    TGO: 'tg',
    TKL: 'tk',
    TON: 'to',
    TTO: 'tt',
    TUN: 'tn',
    TUR: 'tr',
    TKM: 'tm',
    TCA: 'tc',
    TUV: 'tv',
    UGA: 'ug',
    UKR: 'ua',
    ARE: 'ae',
    UMI: 'um',
    URY: 'uy',
    UZB: 'uz',
    VUT: 'zu',
    VAT: 'va',
    VEN: 've',
    VNM: 'vn',
    VGB: 'vg',
    VIR: 'vi',
    WLF: 'wf',
    ESH: 'eh',
    YEM: 'ye',
    YUG: 'yu',
    ZAR: 'zr',
    ZMB: 'zm',
    ZWE: 'zw'
  }

  return codes[threeLetterCode]
}