// @stripe/stripe-js docs: https://github.com/stripe/stripe-js
import {loadStripe} from "@stripe/stripe-js"
import loadStripeCardElement from "../stripe/stripe_card_element.js"
import {preventDoubleSubmit, enableSubmit} from "../helpers/prevent_double_submit.js"
import {userBasedOnDonationForm} from "../entities/user_creators.js"
import {donationPayment} from "./payment_types/donation_payment.js"
import {stripeOneTimeDonationCreator} from "../stripe/payment_creators/stripe_one_time_donation.js"
import {stripeRecurringDonationCreator} from "../stripe/payment_creators/stripe_recurring_donation.js"
import {stripeOneTimeDonationProcessor} from "../stripe/processor_creators/stripe_one_time_donation_processor.js"
import {stripeRecurringDonationProcessor} from "../stripe/processor_creators/stripe_recurring_donation_processor.js"

export const donation = async () => {
  const stripe = await loadStripe(
    gon.stripe_publishable_key,
    { stripeAccount: gon.connected_account_id }
  )

  const card = loadStripeCardElement(stripe);

  const form_button = document.getElementById("donate-now")

  form_button.addEventListener("click", (event) => {
    event.preventDefault()

    if (form_button.classList.contains("disabled")) {
      return
    } else {
      preventDoubleSubmit(form_button)
    }

    Spinner.start()

    let validateOnBlur = false;
    // let validationTimeout = gon.TEST_ENV ? 30000 : 15000;

    const form = document.getElementById("new_donate_now_form")

    validateForm(form.id).then((result) => {
      // All clear, continue processing payment

      const user = new userBasedOnDonationForm()

      const donation = donationPayment()

      // TODO: Need to determine whether need to pass 'form' to
      // stripeOneTimeDonationCreator or PaymentProcessor
      // (currently passing to both)

      // Create a new payment object using Stripe as the payment processor
      let stripeDonation, stripePaymentProcessor

      if( donation.frequency == "one-time") {
        stripeDonation = stripeOneTimeDonationCreator(
          {
            stripe: stripe,
            card: card,
            form: form,
            form_button: form_button,
            user: user,
            donation: donation,
          }
        )

        stripePaymentProcessor =
          stripeOneTimeDonationProcessor({ stripeDonation: stripeDonation })
      } else {
        stripeDonation = stripeRecurringDonationCreator(
          {
            stripe: stripe,
            card: card,
            form: form,
            form_button: form_button,
            user: user,
            donation: donation,
          }
        )

        stripePaymentProcessor =
          stripeRecurringDonationProcessor({ stripeDonation: stripeDonation })
      }

      // Process the payment
      stripePaymentProcessor.processPayment()
    })
    .catch((errors) => {
      // Root Funding Errors found - alert user

      if (typeof(form_button) !== "undefined") {
        enableSubmit(form_button)
      }

      Spinner.stop()
      // Send errors to server side for processing

      // If there are pre-existing invalid fields, create a concatenated
      // error string (composed of all errors).
      // Note: While ValidationUtils.validateForm will typically return
      // the errors, if there are pre-existing errors, only the count of
      // errors is returned.
      errors = ValidationUtils.prepErrors(errors);

      // if there are errors, log them
      if (errors.length) {
        // TODO: Migrate to fetch
        $.ajax({
          url: "/log_error.js",
          type: "POST",
          dataType: "json",
          data: {
            type: "form_invalid",
            error: errors,
            displayed: true,
            form_data: ValidationUtils.formData()
          }
        });
      }

      ValidationUtils.scrollToFirstError($("#new_donate_now_form"));

      // Re-enable validation on tab-off
      validateOnBlur = true;
    })
  })
}

const validateForm = async (formId) => {
  // TODO: Put is try/catch block - return could not process, try again msg
  return await ValidationUtils.validateForm(formId)
}