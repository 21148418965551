export const stripeOneTimeDonationProcessor = ({ stripeDonation, }) => {
  const processPayment = async () => {
    try {
      stripeDonation.hideNonFieldSpecificError()

      let human = await stripeDonation.validateHuman()

      if (human) {
        const intentParams =
          stripeDonation.setPaymentIntentParamsFromDonationForm()

        // setting and getting data from session
        const sessionCampaignId = sessionStorage.getItem("campaignId")
        let customerIdStripe = sessionStorage.getItem("customerIdStripe")

        if (customerIdStripe && sessionCampaignId != stripeDonation.campaignId) {
          sessionStorage.clear()

          customerIdStripe = null
        }

        if (!customerIdStripe) {
          customerIdStripe = await stripeDonation.createStripeCustomer()
        }

        const paymentMethod = await stripeDonation.createStripePaymentMethod()

        const latestPaymentIntentStatus = sessionStorage.getItem(
          "latestPaymentIntentStatus"
        )

        let paymentIntent = null

        if (latestPaymentIntentStatus === "requires_payment_method") {
          const paymentIntentId = sessionStorage.getItem("paymentIntentId")
          const stripePaymentIntentId =
            sessionStorage.getItem("stripePaymentIntentId")

          paymentIntent =
            await stripeDonation.updatePaymentIntent({
              intentParams: intentParams,
              paymentIntentIdStripe: paymentIntentId,
              paymentMethodIdStripe: paymentMethod.id,
              stripePaymentIntentId: stripePaymentIntentId
            })

          paymentIntent =
            await stripeDonation.handleStripePaymentRequiresCustomerAction({
              initialPaymentIntent: paymentIntent,
              paymentMethodId: paymentMethod.id,
              isRetry: true,
            })
        } else {
          paymentIntent = await stripeDonation.createPaymentIntent({
            intentParams: intentParams,
            customerIdStripe: customerIdStripe,
            paymentMethodIdStripe: paymentMethod.id
          })

          paymentIntent =
            await stripeDonation.handleStripePaymentRequiresCustomerAction({
              initialPaymentIntent: paymentIntent,
              paymentMethodId: paymentMethod.id,
              isRetry: false,
            })

          if (paymentIntent.status === "requires_payment_method") {
            stripeDonation.handleStripeRequiresPaymentMethod({
              campaignId: stripeDonation.campaignId,
              customerIdStripe: customerIdStripe,
              paymentIntent: paymentIntent,
            })
          }
        }

        if (paymentIntent.status == "succeeded") {
          stripeDonation.completePayment(paymentIntent.id)
        } else {
          // TODO: How handle status != "succeeded"??
        }
      }
    }  catch(e) {
      stripeDonation.handleStripeProcessingError({
        e: e,
        campaignId: stripeDonation.campaignId,
        paymentType: "donation",
      })
    }
  }

  return {
    processPayment,
  }
}