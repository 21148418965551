import {canHideNonFieldSpecificError} from "./capabilities/can_hide_non_field_specific_error.js"
import {canCreateStripeCustomer} from "./capabilities/can_create_stripe_customer.js"
import {canCreateStripePaymentMethod} from "./capabilities/can_create_stripe_payment_method.js"
import {canRetryInvoiceWithNewPaymentMethod} from "./capabilities/can_retry_invoice_with_new_payment_method.js"
import {canHandleStripePaymentRequiresCustomerAction} from "./capabilities/can_handle_stripe_payment_requires_customer_action.js"
import {canCreateStripeSubscription} from "./capabilities/can_create_stripe_subscription.js"
import {canHandleStripeRequiresPaymentMethod} from "./capabilities/can_handle_stripe_requires_payment_method.js"
import {canCompletePayment} from "./capabilities/can_complete_payment.js"
import {canHandleStripeProcessingErrors} from "./capabilities/can_handle_stripe_processing_errors.js"

export const stripeRecurringPaymentCreator = ({ stripe, card, form, form_button,
                                                causeSubscription,
                                                paymentType, }) => {
  return Object.assign(
    causeSubscription,
    canHideNonFieldSpecificError(),
    canCreateStripeCustomer({
      causeSubscription: causeSubscription, paymentType: paymentType,
    }),
    canCreateStripePaymentMethod({
      billingName: causeSubscription.cardholderName,
      stripe: stripe,
      card: card,
      email: gon.current_user_email,
      country: causeSubscription.cardholderCountry,
      zip: causeSubscription.cardholderZip,
    }),
    canRetryInvoiceWithNewPaymentMethod({
      causeSubscription: causeSubscription,
      paymentType: paymentType,
    }),
    canHandleStripePaymentRequiresCustomerAction({ stripe: stripe, }),
    canCreateStripeSubscription({
      causeSubscription: causeSubscription,
      paymentType: paymentType,
    }),
    canHandleStripeRequiresPaymentMethod(),
    canCompletePayment({ form: form, }),
    canHandleStripeProcessingErrors({ form_button: form_button }),
  )
}