// Source: https://gomakethings.com/how-to-test-if-an-element-is-in-the-viewport-with-vanilla-javascript/
export const isInViewport = function (elem) {
  var bounding = elem.getBoundingClientRect()
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const scrollElementToTop = (elem, { spacerPixels = 10 } = { spacerPixels: 10 }) => {
  const elemBox = elem.getBoundingClientRect()

  const desktopNavbar = document.getElementsByClassName("rf-desktop-navbar")[0]
  const mobileNavbar = document.getElementsByClassName("rf-mobile-navbar")[0]

  let navbarBox = 0
  if (window.getComputedStyle(desktopNavbar).display !== "none") {
    navbarBox = desktopNavbar.getBoundingClientRect()
  } else if (window.getComputedStyle(mobileNavbar).display !== "none") {
    navbarBox = mobileNavbar.getBoundingClientRect()
  }

  window.scroll(
    {
      top: elemBox.top + window.pageYOffset - (navbarBox.height + spacerPixels),
      behavior: "smooth"
    }
  )
}