export function causeSubscriptionPayment() {
  const causeSubscriptionProperties = {
    causeId: gon.cause_id,
    cardholderName: document.getElementById("cardholder_name").value.trim(),
    cardholderCountry: document.getElementById("cardholder_country").value,
    cardholderZip: document.getElementById("cardholder_zip").value.trim(),
  }

  return {
    ...causeSubscriptionProperties
  }
}