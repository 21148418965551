import {FailedToValidateHumanError} from "../../../error_handling/error_types.js"

export const canValidateHuman = ({ user, donation, causeSubscription, paymentType = "donation" }) => ({
  validateHuman: async () => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    let postParams = {}

    // user is only passed in for donation by user that is not logged in
    if (typeof(user) != "undefined") {
      postParams.email = user.email
      postParams.password = user.password
      postParams.firstname = user.firstName
      postParams.lastname = user.lastName
      postParams.country = user.country
      postParams.zip = user.zip
    }

    if (typeof(gon.current_user_name) != "undefined") {
      postParams.current_user_name = gon.current_user_name
    }

    if (typeof(gon.current_user_email) != "undefined") {
      postParams.current_user_email = gon.current_user_email
    }

    if (typeof(donation) != "undefined") {
      postParams.campaign_id = donation.campaignId
    } else if (typeof(causeSubscription) !== "undefined") {
      postParams.cause_id = causeSubscription.causeId
    } else {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_users`: Neither `donation` nor " +
        "`causeSubscription` was set"
      )
    }

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response = await fetch("/humans", {
      headers: headerContent,
      method: "POST",
      body: JSON.stringify(postParams)
    })

    if (!response.ok) {
      // Network error
      throw new FailedToValidateHumanError("Failed to validate human'")
    }

    try {
      const validationResponse = await response.json()

      if (validationResponse.error) {
        throw validationResponse
      }

      if (!validationResponse.human) {
        throw new FailedToValidateHumanError(
          "Failed to fetch `/humans`: `human` not set"
        )
      }

      return validationResponse.human
    }  catch(e) {
      throw new FailedToValidateHumanError("Failed to validate human'")
    }
  }
})