export class RootFundingError extends Error {
  constructor(message, { display_message = null }) {
    super(message)
    this.name = "RootFundingError"
    this.display_message = display_message
  }
}

class UnableToCreatePaymentIntentError extends RootFundingError {
  constructor(message, { display_message = null }) {
    super(message, {display_message: display_message})
    this.name = "UnableToCreatePaymentIntentError"
  }
}

export class FailedToFetchError extends RootFundingError {
  constructor(message, { display_message } = {}) {
    super(message, {display_message: display_message})
    this.name = "FailedToFetchError"
  }
}

export class FailedToValidateHumanError extends RootFundingError {
  constructor(message, { display_message } = {}) {
    super(message, {display_message: display_message})
    this.name = "FailedToValidateHumanError"
  }
}

export class StripeRequiresPaymentMethod extends RootFundingError {
  constructor(message, { display_message } = {}) {
    super(message, { display_message: display_message })
    this.name = "StripeRequiresPaymentMethod"
  }
}

export class StripeError extends RootFundingError {
  constructor({ stripe_error, display_message = null }) {
    super(stripe_error.message, {display_message: display_message})
    this.name = "StripeError"
    this.error = stripe_error
  }
}