export const stripeCauseSubscriptionProcessor = ({ stripeCauseSubscription, }) => {
  const processPayment = async () => {
    try {
      const causeId = gon.cause_id
      const causePlan = gon.cause_plan

      stripeCauseSubscription.hideNonFieldSpecificError()

      const sessionCauseId = sessionStorage.getItem("causeId")
      let customerIdStripe = sessionStorage.getItem("customerIdStripe")

      if (customerIdStripe && sessionCauseId != causeId) {
        sessionStorage.clear()

        customerIdStripe = null
      }

      if (!customerIdStripe) {
        customerIdStripe = await stripeCauseSubscription.createStripeCustomer()
      }

      // TODO: Need to also set country and zip
      const paymentMethod = await stripeCauseSubscription.createStripePaymentMethod()

      const latestInvoicePaymentIntentStatus = sessionStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      )

      let paymentIntent = null

      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = sessionStorage.getItem("latestInvoiceId")

        const invoice = await stripeCauseSubscription.retryInvoiceWithNewPaymentMethod({
          invoiceId: invoiceId,
          customerId: customerIdStripe,
          paymentMethodId: paymentMethod.id,
        })

        paymentIntent =
          await stripeCauseSubscription.handleStripePaymentRequiresCustomerAction({
            initialPaymentIntent: invoice.payment_intent,
            paymentMethodId: paymentMethod.id,
            isRetry: true,
          })
      } else {
        const subscription = await stripeCauseSubscription.createStripeSubscription({
          customerId: customerIdStripe,
          paymentMethodId: paymentMethod.id,
          priceId: causePlan,
        })

        if (subscription.status === "active") {
          // Subscription is active, no customer actions required.
          paymentIntent = subscription.latest_invoice.payment_intent
        } else {
          paymentIntent =
            await stripeCauseSubscription.handleStripePaymentRequiresCustomerAction({
              initialPaymentIntent: subscription.latest_invoice.payment_intent,
              paymentMethodId: paymentMethod.id,
              isRetry: false,
            })
        }

        if (paymentIntent.status === "requires_payment_method") {
          stripeCauseSubscription.handleStripeRequiresPaymentMethod({
            causeId: causeId,
            customerIdStripe: customerIdStripe,
            subscription: subscription,
          })
        }
      }

      if (paymentIntent.status == "succeeded") {
        stripeCauseSubscription.completePayment(paymentIntent.id)
      } else {
        // TODO: How handle status != "succeeded"??
      }
    } catch(e) {
      stripeCauseSubscription.handleStripeProcessingError({
        e: e,
        causeId: stripeCauseSubscription.causeId,
        paymentType: "subscription payment",
      })
    }
  }

  return {
    processPayment,
  }
}