export const canSetPaymentIntentParamsFromDonationForm = ({ donation, user }) => ({
  setPaymentIntentParamsFromDonationForm: () => {
    const intentParams = {
      campaign_id: donation.campaignId,
      currency_code: donation.currencyCode,
      amount: donation.amount,
    }

    if (typeof(user.email) !== "undefined") {
      intentParams.email = user.email
    }

    if (typeof(user.password) !== "undefined") {
      intentParams.password = user.password
    }

    if (typeof(user.firstName) !== "undefined") {
      intentParams.firstname = user.firstName
    }

    if (typeof(user.lastName) !== "undefined") {
      intentParams.lastname = user.lastName
    }

    if (typeof(user.country) !== "undefined") {
      intentParams.country = user.country
    }

    if (typeof(user.zip) !== "undefined") {
      intentParams.zip = user.zip
    }

    if (typeof(donation.tipPercent) !== "undefined") {
      intentParams.tip_percent = donation.tipPercent
    } else {
      intentParams.tip_percent = 0
    }

    return intentParams
  }
})