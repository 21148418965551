// For checking if a string is blank, null or undefined
export function isBlank(str) {
  return (!str || (/^\s*$/).test(str))
}

// For checking if a string is empty, null or undefined
export function isEmpty(str) {
  return (!str || str.length === 0)
}

export function notBlank(str) {
  return !isBlank(str)
}

// For checking if a string is empty, null or undefined
export function notEmpty(str) {
  return !isEmpty(str)
}