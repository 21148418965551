// Populate donation amount field with value of clicked/selected button
export const donationAmountButtons = () => {
  $(document).on('click', '.donation-amt-btn', function() {
    var donationAmt = $(this).val();

    // Set the donation amount
    $('#donate_now_form_amount').val(donationAmt).change();

    // Validate the field (log errors)
    ValidationUtils.validateField($('#donate_now_form_amount'), true);
  });

  // Show tooltip for donation amount field if user clicks/selects 'Other' button
  $(document).on('click', '.donation-amt-btn-other', function() {
    $('#donate_now_form_amount').tooltip('show');
  });
}