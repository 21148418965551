export function userCreator({ email, password, firstName, lastName, country, zip, }) {
  const userProperties = {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
    country: country,
    zip: zip,
  }

  return {
    ...userProperties,
    fullName: () => `${userProperties.firstName} ${userProperties.lastName}`
  }
}

export function userBasedOnDonationForm() {
  const user = userCreator(
    {
      email: document.getElementById("email").value.trim(),
      password: document.getElementById("create_password").value,
      firstName: document.getElementById("first_name").value.trim(),
      lastName: document.getElementById("last_name").value.trim(),
      country: document.getElementById("country").value.trim(),
      zip: document.getElementById("zip").value.trim(),
    }
  )

  return {
    ...user,
    fullName: () => `${userProperties.firstName} ${userProperties.lastName}`
  }
}
