import {FailedToFetchError} from "../../../error_handling/error_types.js"

export const canRetryInvoiceWithNewPaymentMethod = ({ donation, causeSubscription, paymentType = "donation" }) => ({
  retryInvoiceWithNewPaymentMethod: async ({ invoiceId, customerId,
    paymentMethodId, }) => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }
    const retryStripeInvoiceParams = {
      payment_type: paymentType,
      invoiceId: invoiceId,
      customerId: customerId,
      paymentMethodId: paymentMethodId,
    }

    if (typeof(donation) != "undefined") {
      retryStripeInvoiceParams.campaign_id = donation.campaignId
    } else if (typeof(causeSubscription) !== "undefined") {
      retryStripeInvoiceParams.cause_id = causeSubscription.causeId
    } else {
      throw new FailedToFetchError(
        "Failed to fetch `/retry-stripe-invoice-with-new-payment-method`: " +
        "Neither `donation` nor `causeSubscription` was set"
      )
    }

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response =
      await fetch("/retry-stripe-invoice-with-new-payment-method", {
        headers: headerContent,
        method: "POST",
        body: JSON.stringify(retryStripeInvoiceParams)
      })

    if (!response.ok) {
      // Network error
      throw new FailedToFetchError("Failed to fetch 'retry-stripe-invoice-with-new-payment-method'")
    }

    const invoice = await response.json()

    if (invoice.error) {
      throw invoice
    }

    return invoice
  }
})