import {notBlank} from "../../helpers/string_helpers.js"
import {setBillingName} from "./capabilities/can_set_billing_name.js"
import {canHideNonFieldSpecificError} from "./capabilities/can_hide_non_field_specific_error.js"
import {canCreateCustomProductAndPrice} from "./capabilities/donations/can_create_custom_product_and_price.js"
import {canCreateStripeCustomer} from "./capabilities/can_create_stripe_customer.js"
import {canValidateHuman} from "./capabilities/can_validate_human.js"
import {canCreateStripePaymentMethod} from "./capabilities/can_create_stripe_payment_method.js"
import {canRetryInvoiceWithNewPaymentMethod} from "./capabilities/can_retry_invoice_with_new_payment_method.js"
import {canHandleStripePaymentRequiresCustomerAction} from "./capabilities/can_handle_stripe_payment_requires_customer_action.js"
import {canCreateStripeSubscription} from "./capabilities/can_create_stripe_subscription.js"
import {canHandleStripeRequiresPaymentMethod} from "./capabilities/can_handle_stripe_requires_payment_method.js"
import {canCompletePayment} from "./capabilities/can_complete_payment.js"
import {canHandleStripeProcessingErrors} from "./capabilities/can_handle_stripe_processing_errors.js"

export const stripeRecurringDonationCreator = ({ stripe, card, form,
                                                 form_button, user,
                                                 donation, }) => {
  return Object.assign(
    donation,
    user,
    canHideNonFieldSpecificError(),
    canCreateCustomProductAndPrice({
      donation: donation,
      user: user,
      payment_type: "donation",
    }),
    canCreateStripeCustomer({ donation: donation, user: user,}),
    canValidateHuman({ donation: donation, user: user, }),
    canCreateStripePaymentMethod({
      billingName: setBillingName({
        user: user,
        current_user_name: gon.current_user_name
      }),
      stripe: stripe,
      card: card,
      email: (notBlank(user.email)) ? user.email : gon.current_user_email,
      country: user.country,
      zip: user.zip,
    }),
    canRetryInvoiceWithNewPaymentMethod({ donation: donation, }),
    canHandleStripePaymentRequiresCustomerAction({ stripe: stripe, }),
    canCreateStripeSubscription({ donation: donation, }),
    canHandleStripeRequiresPaymentMethod(),
    canCompletePayment({ form: form, }),
    canHandleStripeProcessingErrors({ form_button: form_button }),
  )
}