export const tip_percent = () => {
  // Only applies if asking for tips (free plan)
  if ($(".donation-confirmation-container").length) {
    // Show other tip amount field when select other on tip_cents
    $(".form-control#donate_now_form_tip_dropdown").on("change", function(e) {
      if ($(this).find("option:selected").val() === "other") {
        $(".tip-percent-section").css("display", "inline-block");
      } else {
        $("#donate_now_form_tip_percent").
          val($(this).find("option:selected").val()).change();

        $(".tip-percent-section").css("display", "none");
      }
    });

    // Update Confirmation amount, tip and total when currency changes
    // $("#donate_now_form_currency_code").on("change", function(e) {
    //   updateConfirmationAmounts();
    // });

    // // Update Confirmation amount, tip and total when amount is changes
    // $("#donate_now_form_amount").on("change", function(e) {
    //   updateConfirmationAmounts();
    // });
  }
}