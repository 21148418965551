export const canCompletePayment = ({ form, }) => ({
  completePayment: (paymentIntentId) => {
    appendPaymentIntentIdToForm(paymentIntentId)

    sessionStorage.clear()

    form.submit()

    function appendPaymentIntentIdToForm(paymentIntentId) {
      const hiddenInput = document.createElement("input")
      hiddenInput.setAttribute("type", "hidden")

      switch (form.id) {
        case "new_donate_now_form":
          hiddenInput.setAttribute("name", "donate_now_form[payment_intent_id_stripe]")
          break;
        case "new_complete_your_membership_form":
          hiddenInput.setAttribute("name", "complete_your_membership_form[payment_intent_id_stripe]")
          break;
        default:
          break;
      }

      hiddenInput.setAttribute("value", paymentIntentId)
      form.appendChild(hiddenInput)
    }
  }
})