import {FailedToFetchError} from "../../../error_handling/error_types.js"

export const canCreateStripeSubscription = ({ donation, causeSubscription, paymentType = "donation" }) => ({
  createStripeSubscription: async ({ customerId, paymentMethodId, priceId, }) => {

    let csrfToken = null

    if (document.querySelector("[name='csrf-token']") != null) {
      // Grab the CSRF token from the meta tag
      csrfToken = document.querySelector("[name='csrf-token']").content
    }

    const stripeCustomerParams = {
      payment_type: paymentType,
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      priceId: priceId,
    }

    if (typeof(donation) != "undefined") {
      stripeCustomerParams.campaign_id = donation.campaignId
      stripeCustomerParams.amountCents = donation.amount * 100
      stripeCustomerParams.tipPercent = donation.tipPercent
    } else if (typeof(causeSubscription) !== "undefined") {
      stripeCustomerParams.cause_id = causeSubscription.causeId
    } else {
      throw new FailedToFetchError(
        "Failed to fetch `/stripe_subscriptions`: Neither `donation` nor " +
        "`causeSubscription` was set"
      )
    }

    let headerContent = {
      "Content-Type": "application/json"
    }

    if (csrfToken != null) {
      headerContent["X-CSRF-Token"] = csrfToken
    }

    const response = await fetch("/stripe_subscriptions", {
      headers: headerContent,
      method: "POST",
      body: JSON.stringify(stripeCustomerParams)
    })

    if (!response.ok) {
      // Network error
      throw new FailedToFetchError("Failed to fetch '/stripe_subscriptions'")
    }

    const subscription = await response.json()

    if (subscription.error) {
      throw subscription
    }

    return subscription
  }
})