// Setup the Stripe card element on the form
export default function loadStripeCardElement(stripe) {
  var elements = stripe.elements();
  var style = {
    invalid: {
      color: '#a94442',
      iconColor: '#a94442'
    }
  };

  var card = elements.create('card', {
    hidePostalCode: true,
    style: style
  });

  // Add an instance of the card UI component into the `card-element` <div>
  card.mount('#card-element');

  // Elements validates user input as it is typed. To help your customers catch
  // mistakes, you should listen to change events on the card Element and
  // display any errors:
  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');

    // Remove the card-element-empty class if the event is not empty
    if (!event.empty) {
      $("#card-element").removeClass('card-element-empty');
    }

    // Remove card-element-invalid class
    $("#card-element").removeClass('card-element-invalid');

    if (event.error) {
      // Set the Stripe card element to invalid (decorate as invalid)
      ValidationUtils.setStripeInvalid(event.error.message);

      // Log the Stripe error
      $.ajax({
        url: '/log_error.js',
        type: 'POST',
        data: {
          type: 'stripe_field_invalid',
          error: event.error.message,
          displayed: true,
          form_data: ValidationUtils.formData()
        }
      });
    } else if (event.empty) {
      // Decorate the card element as empty
      $("#card-element").addClass('card-element-empty');

      ValidationUtils.setStripeInvalid("Card information can't be blank");

    } else if (event.complete) {
      // All fields in the Stripe card element are populated with valid data

      // decorate the card errors div as successful
      ValidationUtils.setStripeValid();

    } else {
      // The stripe card element is not yet complete, but also is not invalid

      // Remove a class indicating that there is a field in stripe card element
      // that is in an invalid state
      $(".card-errors").removeClass('stripe-error-displayed');

      // Remove the red 'x' for invalid field
      $(".stripe-card-element").find(".glyphicon").removeClass('glyphicon-remove');
      displayError.textContent = '';
    }
  });

  // Hide the card error when the card element loses focus
  card.addEventListener('blur', function(event) {
    $(".card-errors").hide();

    // If the card info is not complete and there is isn't an error message
    // Display "can't be blank" error
    if (!$('#card-element').hasClass('StripeElement--complete')) {
      if ($('#card-errors').text().length < 1) {
        ValidationUtils.setStripeInvalid("Card information can't be blank");
      }
    }
  });

  // Show the card error when the card element gains focus (if there is error)
  card.addEventListener('focus', function(event) {
    $(".card-errors").show();
  });

  return card;
}
