export function donationPayment() {
  const donationProperties = {
    campaignId: document.getElementById("donate_now_form_campaign_id").value,
    currencyCode: document.getElementById("donate_now_form_currency_code").value,
    amount: parseFloat(
              document.getElementById("donate_now_form_amount").value.trim()
            ),
    frequency: document.getElementById("donate_now_form_frequency").value,
  }

  if (document.getElementById("donate_now_form_tip_percent")) {
    donationProperties.tipPercent =
      parseFloat(
        document.getElementById("donate_now_form_tip_percent").value.trim()
      )
  }

  return {
    ...donationProperties
  }
}