import log_error from "../../../error_handling/log_error.js"
import {StripeError} from "../../../error_handling/error_types.js"
import {scrollElementToTop} from "../../../utilities/viewport.js"
import {enableSubmit} from "../../../helpers/prevent_double_submit.js"

export const canHandleStripeProcessingErrors = ({ form_button, }) => ({
  handleStripeProcessingError: ({ e, campaignId, causeId, paymentType, }) => {
    if (e instanceof StripeError) {
      // Stripe javascript error (e.g., createPaymentMethod)

      let errorLogDetail = {
        type: "stripe",
        response: e,
        campaign_id: campaignId,
        cause_id: causeId,
      }

      switch (e.error.type) {
        case "card_error":
          ValidationUtils.setStripeInvalid(e.error.message, true)

          errorLogDetail.display_message = e.error.message
          break;
        case "invalid_request_error": {
          let displayMessage = ""
          if (e.error.code == "payment_intent_authentication_failure") {
            displayMessage = e.error.message
          } else {
            displayMessage = genericDisplayMessage({ paymentType: paymentType })
          }

          displayNonFieldSpecificError(displayMessage)

          errorLogDetail.display_message = displayMessage
          break;
        }
        case "rate_limit_error":
        case "authentication_error":
        case "api_connection_error":
        case "api_error":
        case "stripe_error":
        case "validation_error":
        case "idempotency_error":
        default: {
          const displayMessage =
            genericDisplayMessage({ paymentType: paymentType, })

          displayNonFieldSpecificError(displayMessage)

          errorLogDetail.display_message = displayMessage
          break;
        }
      }

      log_error(errorLogDetail)
    } else if (e.type == "Stripe::CardError") {
      // Stripe server side card error (Stripe error which
      // was forwarded for front end interaction)
      // Note: These errors are logged server side

      ValidationUtils.setStripeInvalid(e.error.message, true)
    } else if (e.type == "Stripe::RateLimitError" ||
                e.type == "Stripe::InvalidRequestError" ||
                e.type == "Stripe::AuthenticationError" ||
                e.type == "Stripe::APIConnectionError" ||
                e.type == "Stripe::StripeError" ||
                e.type == "generic_server_side_error") {
      // Error originated from RF server side (Stripe error which
      // was forwarded for front end interaction)
      // Note: These errors are logged server side

      let displayMessage = ""
      if (typeof(e.display_error) !== "undefined") {
        displayMessage = e.display_error
      } else {
        displayMessage = genericDisplayMessage({ paymentType: paymentType, })
      }
      displayNonFieldSpecificError(displayMessage)
    } else if (e.name == "FailedToFetchError") {
      // Error fetching from RF server side
      let displayMessage = e.display_message ? e.display_message :
        genericDisplayMessage({ paymentType: paymentType, })

      displayNonFieldSpecificError(displayMessage)

      log_error(
        {
          type: "failed_to_fetch_error",
          message: e.message,
          display_message: displayMessage,
          campaign_id: campaignId,
          cause_id: causeId,
        }
      )
    } else if (e.name == "StripeRequiresPaymentMethod") {
      // Card was declined / needs new payment method

      let displayMessage = e.display_message ? e.display_message :
        genericDisplayMessage({ paymentType: paymentType, })

      ValidationUtils.setStripeInvalid(displayMessage, true)

      log_error({
        type: "stripe_requires_payment_method",
        message: e.message,
        display_message: displayMessage,
        campaign_id: campaignId,
        cause_id: causeId,
      })
    } else if (e.name == "FailedToValidateHumanError") {
      // Do not log error
      location.reload()
    } else {
      // Generic handling for all other error types

      let displayMessage =
        genericDisplayMessage({ paymentType: paymentType, })

      displayNonFieldSpecificError(displayMessage)

      log_error(
        {
          type: "generic",
          message: e.message,
          display_message: displayMessage,
          campaign_id: campaignId,
          cause_id: causeId,
        }
      )
    }

    if (typeof(form_button) !== "undefined") {
      enableSubmit(form_button)
    }

    Spinner.stop()

    // Returns generic message of the specified paymentType
    function genericDisplayMessage({ paymentType, } = { paymentType: "payment", }) {
      return `We could not process your ${paymentType}, please try again`
    }

    // Displays non-field specific errors (i.e, errors not displayed on a
    // specific input field)
    function displayNonFieldSpecificError(message) {
      const alertRow = document.getElementById("generic-form-error-row")
      const alertDiv = document.getElementById("generic-form-error")

      const originalContent = alertDiv.firstChild

      const content = document.createTextNode(message)

      alertDiv.replaceChild(content, originalContent)

      alertRow.classList.remove("hidden")

      scrollElementToTop(alertRow)
    }
  }
})